import type { FC } from 'react'

const sectionCn = 'flex flex-col mx-10'
const sectionTitleCn = 'font-bold text-xl py-4'
const linkCn = 'mb-2 text-base font-medium hover:text-primary'

export const Footer: FC<{}> = () => {
  return (
    <footer className="bg-gray-100 w-full flex flex-wrap flex-row justify-center">
      <div className={sectionCn}>
        <h5 className={sectionTitleCn}>Company</h5>
        <a
          className={linkCn}
          href="https://appfluence.com/?utm_campaign=teams-reminder"
          target="_blank"
          rel="noreferrer"
        >
          Appfluence
        </a>
      </div>
      <div className={sectionCn}>
        <h5 className={sectionTitleCn}>Apps</h5>
        <a
          className={linkCn}
          href="https://prioritymatrix.com/?utm_campaign=teams-reminder"
          target="_blank"
          rel="noreferrer"
        >
          Priority Matrix
        </a>
        <a
          className={linkCn}
          href="https://delayedsend.com/?utm_campaign=teams-reminder"
          target="_blank"
          rel="noreferrer"
        >
          DelayedSend
        </a>
      </div>
    </footer>
  )
}
