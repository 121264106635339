import { FC } from 'react'
import { VerticalFeatureRow } from '@/components/features/VerticalFeatureRow'
import { Section } from '@/components/layout/Section'
import { HeaderBar } from '@/components/HeaderBar'
import { Button } from '@fluentui/react-northstar'
import Link from 'next/link'
import { Footer } from '@/components/Footer'

const Landing: FC = () => {
  return (
    <>
      <HeaderBar showLogOutButton={false} />
      <div className="bg-gray-100">
        <Section
          title="Schedule reminders in Microsoft Teams"
          description="Never forget anything important. Ask Microsoft Teams to remind you of what you need to know, at the right time."
        >
          <Button
            primary
            as="a"
            href="https://teams.microsoft.com/l/app/ae64c32a-0eab-47d9-91e4-b693569f7fc3"
            target="_blank"
            className="text-2xl !px-8 !py-6 !mx-4"
          >
            Open in Teams
          </Button>
          <Link href="/app" passHref legacyBehavior>
            <Button as="a" className="text-2xl !px-8 !py-6 !mx-4">
              Dashboard
            </Button>
          </Link>
        </Section>
      </div>
      <Section>
        <VerticalFeatureRow
          title="Timely reminders for any occasion"
          description="We all have too many things in our heads. It's just not sustainable. Instead of juggling work tasks with home chores, birthdays, restaurant reservations and what not, schedule timely reminders. Free your mind so you can focus on what's important."
          image="/assets/images/feature.svg"
          imageAlt="Scheduling Microsoft Teams messages to be sent in the future is easy."
        />
        <VerticalFeatureRow
          title="Cancel or reschedule reminders"
          description="If you change your mind and the reminder is no longer necessary, use the dashboard to change its content or delivery time. Or simply to cancel it altogether."
          image="/assets/images/feature2.svg"
          imageAlt="If you change your mind, you can change your reminders."
          reverse
        />
        <VerticalFeatureRow
          title="Completely Free"
          description="Teams Reminder is a free service by Appfluence, the makers of Priority Matrix. Basic service will remain free, and we may add power features at a premium."
          image="/assets/images/feature3.svg"
          imageAlt="Free reminders app for Microsoft Teams."
        />
      </Section>
      <Footer />
    </>
  )
}

export default Landing
